import React, { useState, useEffect } from 'react'
import { Button } from 'react-bootstrap';
import "../styles/homeScreen.css";
import background from "../styles/Images/niqbg.png";
import niqLogo from "../styles/Images/niqlogo.png"
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import { SignInButton } from './SignInButton';
import { useIsAuthenticated } from "@azure/msal-react";


export const LoginPage = () => {
  const { instance } = useMsal();
  const [userName, setUsername] = useState('');
  const isAuthenticated = useIsAuthenticated();   

  useEffect(() => {
    instance.loginRedirect(loginRequest).catch(e => {
      console.log(e);
    });
  }, [isAuthenticated])
  const handleLogin = () => {
    instance.loginRedirect(loginRequest).catch(e => {
    });
  }
  const onChangeHandler = (e) => {
    setUsername(e.target.value);
  }
  return (
    <div>
    <div className='article'>
      <div className='login-bg-image'>
        <img src={background} alt="background" />
      </div>
      <div className="container">
        <div className="box">
          <div className="box-row">
            <div className="box-cell box1">
              <h1 className='header'>Discover complete consumer intelligence.</h1>
            </div>
            <div className="box-cell box2">
              <div className='login-floating'>
                <img className='login-niq-logo' alt='NielsenIQ logo'
                  src={niqLogo} />
                <div>
                  <div className='text-login'>Username</div>
                  <input className='email-login'
                    type="text" name="username" value={userName} onChange={onChangeHandler} placeholder='Please Enter Email ' />
                  {/* <SignInButton /> */}
                  <Button className="btn-login" onClick={() => handleLogin()}>Login</Button>
                </div>
              </div>
              {/* <div className="headerL">Float Column 2</div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  )
}
