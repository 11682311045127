import React from 'react'
import { Dropdown } from 'react-bootstrap'
import { useMsal } from "@azure/msal-react";
import "../styles/topbar.css"
import ciLogo from "../styles/Images/ci_logo.png"
import { normalizeEmail } from '../utils/NormalizeEmail';

const TopBar = (props) => {
    const { instance } = useMsal();
    if (process.env.NODE_ENV === 'development') {
        console.log('Running in development mode');
    } else if (process.env.NODE_ENV === 'production') {
        console.log('Running in production mode');
    } else {
        console.log('Environment mode not recognized');
    }

    const logout = () => {
        instance.logoutRedirect({
            postLogoutRedirectUri: "/",
        });
    }

    return (
        <div className="header-block fixed-top bg-white border-bottom">
            <div className="col-sm-12 col-lg12 col-md-12 ">
                <div className="row" style={{ alignItems: 'center', marginTop: '7px' }}>
                    <div className="col-sm-3 col-md-3 col-lg-3 mr-md-1 mr-lg-2">
                        {/* <Sidebar className="project-menu" visible={sideMenuVisible && !hideSideMenu} onHide={() => { }}>
                <PanelMenuComponent model={sideMenuItems} className="content-wrapper" />
            </Sidebar> */}
                        {/* <div className="row" style={{ alignItems: 'center' }}>
                            <div className="col"> */}
                        {/* <Link to="/"> */}
                        {/* <img className="navbar-brand" src={ciLogo} alt="logo" style={{ objectFit: 'contain', width: "100%" }} /> */}
                        {/* </Link> */}
                        {/* </div>
                        </div> */}
                        <div className="row" style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                            <div className="col d-flex justify-content-center">
                                <img className="navbar-brand" src={ciLogo} alt="logo" style={{ objectFit: 'contain', width: '100%' }} />
                            </div>
                        </div>

                    </div>
                    <div className="col-sm-6 col-md-auto col-lg-auto">
                        {/* <Menubar className="main-menu" model={menuItems} ></Menubar> */}
                    </div>
                    <div className="col-sm-3 col-md-3 col-lg-auto ml-auto mt-1 pl-0">
                        <Dropdown drop="down" >
                            <Dropdown.Toggle id="dropdown-basic">
                                <i className="fa fa-user-circle-o"></i> <span className="username">
                                    {props.userDetails ? normalizeEmail(props.userDetails.toLowerCase()) : ''}
                                </span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item>
                                    <span
                                        onClick={logout}
                                    >Log Out</span>
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>

                    </div>

                </div>
            </div>
        </div>
    )
}

export default TopBar