import { Outlet, Navigate } from 'react-router-dom';
import { useIsAuthenticated } from "@azure/msal-react";
import { useLocation } from "react-router";

const ProtectedRoutes = () => {
    const isAuthenticated = useIsAuthenticated();
    const location = useLocation();

    return isAuthenticated ? (
        <Outlet />
    ) : (
        <Navigate to="/" replace state={{ from: location }} />
    );
}
export default ProtectedRoutes;
