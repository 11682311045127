const getServiceEndpoints = () => {
    return {
        CI_SERVICE: '/apigateway/ci-portal-ui-service',
    }
}

const getDevServiceEndpoints = () => {
    return {
        CI_SERVICE: 'http://localhost:8082',        
    }
}

export const API_END_POINT = {
    BASE_URL: process.env.NODE_ENV === 'development' ? getServiceEndpoints() : getServiceEndpoints()
}