import React from 'react'

const Footer = () => {
  return (
    <div className="footer">
            Version 1.0.1 © Copyright 2024, The NielsenIQ Consumer LLC. Never guess. All Rights Reserved. Privacy Policy. Terms of Use.

        </div>
  )
}

export default Footer