import React, { useState, useEffect } from 'react'
import { loginRequest } from "../authConfig";
import { SignInButton } from './SignInButton';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal,useIsAuthenticated } from "@azure/msal-react";
import { ProfileContent } from '../App';

const FirstPage = () => {
    const { instance } = useMsal();
    const isAuthenticated = useIsAuthenticated();

    useEffect(() => {
        instance.loginRedirect(loginRequest).catch(e => {
            console.log("first page",e);
        });
    }, [isAuthenticated])
    return (
        <AuthenticatedTemplate>
        <ProfileContent />
    </AuthenticatedTemplate>
    )
    // return null
}

export default FirstPage